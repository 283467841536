<template>
  <a-form :form="form" @submit="onSubmit" >
    <a-form-item label="Наименование Атрибута">
      <a-input v-decorator="[
          'Name',
          { initialValue: formData.Name,
          rules: [{ required: true, message: 'Пожалуйста заполните Наименование Атрибута' }] },
        ]" />
    </a-form-item>

    <a-form-item label="Тип">
      <a-select
        @change="selectType"
        placeholder="Тип"
        v-decorator="[
          'Type',
          { initialValue: formData.Type,
          rules: [{ required: true,
          message: 'Пожалуйста выберите Тип' }] }, ]">
        <a-select-option
          v-for="type in types"
          :key="type"
          :value="type">
          {{ type }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item>
      <a-button type="primary" :loading="loading" @click="onSubmit">
        Сохранить
      </a-button>
      <a-button style="margin-left: 10px;">
        <router-link :to="{ name: 'attributes-list' }">Отмена</router-link>
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { formatResponseValidatorFields } from '../../helpers';

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'attribute' });
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      loading: false,
      formData: [],
      types: [],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    onSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          this.$api.saveAttribute(this.$router.currentRoute.params, values, () => {
            this.$router.push({ name: 'attributes-list' });
            this.loading = false;
          }, ({ data, status }) => {
            const fields = formatResponseValidatorFields(data, values);
            this.loading = false;
            if (status === 422) {
              this.form.setFields(fields);
            }
          });
        }
        this.loading = true;
      });
    },
    fetch() {
      this.$api.getAttribute(this.$router.currentRoute.params, false, (response) => {
        const { data } = response;
        this.formData = data.data;
      });

      this.$api.getAttributeTypes(false, ({ data }) => {
        this.types = data.data;
      });
    },
    selectType(value) {
      this.form.setFieldsValue({
        Type: value,
      });
    },
  },
};
</script>
