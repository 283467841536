var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.onSubmit}},[_c('a-form-item',{attrs:{"label":"Наименование Атрибута"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'Name',
        { initialValue: _vm.formData.Name,
        rules: [{ required: true, message: 'Пожалуйста заполните Наименование Атрибута' }] } ]),expression:"[\n        'Name',\n        { initialValue: formData.Name,\n        rules: [{ required: true, message: 'Пожалуйста заполните Наименование Атрибута' }] },\n      ]"}]})],1),_c('a-form-item',{attrs:{"label":"Тип"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'Type',
        { initialValue: _vm.formData.Type,
        rules: [{ required: true,
        message: 'Пожалуйста выберите Тип' }] } ]),expression:"[\n        'Type',\n        { initialValue: formData.Type,\n        rules: [{ required: true,\n        message: 'Пожалуйста выберите Тип' }] }, ]"}],attrs:{"placeholder":"Тип"},on:{"change":_vm.selectType}},_vm._l((_vm.types),function(type){return _c('a-select-option',{key:type,attrs:{"value":type}},[_vm._v(" "+_vm._s(type)+" ")])}),1)],1),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.onSubmit}},[_vm._v(" Сохранить ")]),_c('a-button',{staticStyle:{"margin-left":"10px"}},[_c('router-link',{attrs:{"to":{ name: 'attributes-list' }}},[_vm._v("Отмена")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }